/* GLOBAL STYLESHEET */

@import './colors.scss';
@import './config.scss';

body {
  background-color: $white;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 100;
}

label {
  font-size: 0.75em;
  display: block;
  font-weight: bold;
  margin-left: 3px;
  margin-bottom: 5px;
}

.container {
  box-sizing: border-box;
  max-width: 500px;
  padding: 0px 15px;
  margin: 0px auto;
}

input[type='submit'].brand,
button.brand {
  font-weight: 100;
  border: none;
  font-size: 15px;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: $radius;
  min-width: 150px;
}

input[type='text'].brand,
input[type='number'].brand,
input[type='password'].brand,
input[type='email'].brand,
textarea.brand,
select.brand {
  min-width: 225px;
  font-weight: 100;
  border: none;
  font-size: 15px;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: $radius;
  color: $black;
  background-color: $light;
}

.primary-bg,
.secondary-bg {
  color: $white;
}

.brand-bg {
  color: $black;
  background-color: $light;
}

.primary-bg {
  background-color: $primary;
  color: $white;
}

.secondary-bg {
  background-color: $secondary;
  color: $white;
}

.margin-right {
  margin-right: $small-unit;
}

.margin-bottom {
  margin-bottom: $small-unit;
}

.margin-top {
  margin-top: $small-unit;
}
