@import '../../colors.scss';

.LoadingComponent {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $white;
    width: 100%;
    height: 100%;
    z-index: 10;
    > img {
        width: 100px;
        display: block;
        margin: 40vh auto;
    }
}