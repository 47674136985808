@import '../../colors.scss';
@import '../../config.scss';

.BrowseComponent {
    h3 {
        text-transform: capitalize;
    }
    margin-bottom: 35px;
    >a.banner {
        display: block;
        text-decoration: none;
        background-image: url('../../assets/banner.jpg');
        background-size: cover;
        padding: 50px 15px;
        font-size: 2em;
        color: $white;
        border-radius: $radius;
        >h1,
        >p {
            margin: 0px;
        }
        >p {
            font-size: .5em;
            margin-left: 4px;
        }
        >h1 {
            line-height: .75em;
            margin-bottom: 10px;
        }
    }
    .ListingComponent.green {
        color: green;
    }
    .ListingComponent {
        color: $black;
        text-decoration: none;
        cursor: pointer;
        >ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            font-size: $med-font;
            >li {
                display: inline-block;
                margin-right: 15px;
            }
        }
        >p {
            font-size: $med-font;
        }
        >h3 {
            margin-bottom: 10px;
            margin-top: 25px;
        }
    }
    >select.main-dropdown {
        background-color: $white;
        border: solid thin $black;
        font-weight: bold;
    }
}