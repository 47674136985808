@import '../../colors.scss';
@import '../../config.scss';

.NavbarComponent {
  box-sizing: border-box;
  padding: 10px 25px;
  border-bottom: solid thin $light;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  input {
    width: 200px;
    margin: 0px auto;
    display: block;
  }
  > div {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    max-width: $max-width;
    margin: 0px auto;
  }
  img {
    width: 35px;
    border-radius: 25px;
  }
  > div div:last-child {
    text-align: right;
  }
}
