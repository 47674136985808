@import '../../colors.scss';
@import '../../config.scss';

.CreateComponent {
    textarea {
        width: 100%;
        max-width: 300px;
        height: 100px;
    }
    >p {
        font-size: $med-font;
    }
    select {
        min-width: 0;
    }
}